.alert {
  position: fixed;
  padding: 2em;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  min-width: 25em;
  z-index: 1000;
  text-align: center;
  max-width: 30em;
}

.alert {
  background-color: var(--white);
  border: solid 1px var(--border-color);
}

.alert-title {
  margin-top: 0;
  margin-bottom: .5em;
}

.alert-description {
  margin-bottom: 2em;
  font-weight: normal;
}

.alert-hide {
  top: -50%;
  transition: 500ms;
}

.alert-show {
  top: 50%;
  transition: 500ms;
}

.child-components {
  display: flex;
  justify-content: center;
  gap: 2em;
}
