.meetTheTeamContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0px -30px var(--mobile-text-margin);
}

.trustees-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 3em;
  margin: 2em 0em;
}

.meet-trustees-title {
  text-align: left;
}
