.textfield-container {
  text-align: left;
  flex: 1;
  margin-bottom: 0.5em;
}

.textfield-area {
  overflow: hidden;
  border-radius: 5px;
}

.textfield {
  font-size: 15px;
  padding: 0.7em 1em;
  background-color: transparent;
  outline: none;
  border: none;
  font-family: 'Poppins';
  min-width: 2em;
  width: 100%;
}

.textarea {
  min-height: 10em;
}

.small {
  max-width: 10em;
}

.textfield-label {
  margin: 0;
  text-align: left;
  color: var(--dark-gray);
  font-size: 15px;
}

.error-message {
  color: var(--red);
  font-size: .9em;
}
