.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.confirmation-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2em;
}

.confirmation-image {
  max-height: 25em;
  align-self: stretch;
}

.tick-image {
  max-width: 5em;
  align-self: center;
}

.confirmation-text {
  font-size: 2.5em;
  font-weight: 200;
  margin: 0em var(--web-margin) 1em var(--web-margin);
  color: var(--gray);
}

.boooking-summary-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1em;
  padding-bottom: 4em;
}

.event-summary-container {
  display: flex;
  flex-direction: column;
  gap: .5em;
  background-color: var(--white);
  padding: 2em;
  border-radius: 5px;
  margin-top: 1em;
}

.event-details {
  display: inline-grid;
  grid-template-columns: 0.2fr 1fr;
  align-items: start;
  column-gap: .5em;
}

.event-details-text {
  font-weight: bold;
}

.home-button {
  max-width: 3em;
}

.confirm-buttons {
  display: flex;
  gap: 1em;
}

/* Mobile */
@media screen and (max-width: 480px) {
  .confirmation-text {
    font-size: 1.8em;
    margin: 0em var(--mobile-text-margin) 1em var(--mobile-text-margin);
  }
}
