.donation-section-container {
  text-align: left;
  padding-bottom: 40px;
}

.donation-options {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.donation-extra-info {
  margin-top: -0.5em;
  margin-bottom: 2em;
}

.donation-text {
  margin-bottom: 2em;
  margin-top: 2em;
}

.donation-approx {
  margin-top: -1.5em;
  margin-bottom: 2em;
}

.donate-option {
  font-size: 16px;
  padding: 0.5em 2em;
}

.custom-donate-input {
  font-size: 16px;
}

.custom-donate-input::placeholder {
  color: var(--off-white);
}

.custom-donate-input:hover {
  background-color: transparent;
  color: var(--off-white);
}

/* Mobile */
@media screen and (max-width: 480px) {
  .custom-donate-input {
    max-width: 70%;
  }
  .donation-extra-info {
    margin-top: 20px;
  }
}
