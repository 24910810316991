.App {
  text-align: center;
}

.charity-number {
  font-size: 12px;
  text-align: left;
  align-self: flex-start;
  margin: 0em var(--web-margin);
  color: var(--light-gray);
}

.mock-event-link {
  text-decoration: none;
  cursor: default;
}

/* Mobile */
@media screen and (max-width: 480px) {
  .charity-number {
    margin: 0em var(--mobile-text-margin);
  }
}
