.projectCard {
    margin: 10px;
    width: var(--project-card-width);
    height: var(--project-card-height);
    border: 1px solid var(--border-color);
    cursor: pointer;
    text-decoration: none;
}

.projectImageContainer {
    width: 100%;
    height: 180px;
    position: relative;
    text-align: center;
    padding-bottom: 5px;
}

.projectImage {
   height: 100%;
   width: 100%;
}

.projectImageFilter {
    filter: brightness(50%);
}

.comingSoonLabel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    font-weight: 600;
    font-size: 24px;
    color: var(--white);
}

.projectCardTitle, .projectCardInfo {
    padding: 10px 20px 0px;
}

.projectCardTitle {
    font-weight: 600;
    font-size: 18px;
    text-align: left;
    color: var(--gray);
}

.projectCardInfo {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: var(--light-gray);
}

.readMoreContainer {
    width: var(--project-card-width);
    margin-top: 20px;
}

.readMoreLabel {
    font-weight: 700;
    font-size: 12px;
    text-align: left;
    padding: 0px 20px;
    color: var(--light-gray);
}

.rightArrowImage {
    height: 10px;
    width: 10px;
    padding-top: 4px;
    margin-left: -15px;
}

.projectCardLinkContainer {
    display: flex;
    text-decoration: none;
}

/* Mobile */
@media screen and (max-width: 480px) {
    .projectCard {
        width: var(--project-card-width-mobile);
        height: var(--project-card-height-mobile);
    }
    .projectImageContainer {
        height: 200px;
    }
}
