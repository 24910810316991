.missionStatement, .authorText {
    display: flex;
}

.missionStatement {
    font-weight: 200;
    font-size: 35px;
    line-height: 72px;
    align-items: center;
    text-align: center;
    margin-top: -10px;
}

.authorText {
    font-weight: 300;
    font-size: 18px;
    line-height: 33px;
    align-items: right;
    justify-content: right;
    text-align: right;
    color: var(--white);
    margin: 25px 0px 20px;
    padding-right: var(--mobile-text-margin);
}

/* Mobile */
@media screen and (max-width: 480px) {
    .missionStatement {
        font-size: 20px;
        line-height: 35px;
    }
    .authorText {
        padding-right: 0px;
    }
}
