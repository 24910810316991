.ticket-info {
  display: flex;
  gap: 5em;
  margin-bottom: 1.5em;
}

.book-event-form {
  text-align: left;
}

.booking-costs {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  row-gap: .4em;
  background-color: var(--off-white);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 1em 2em;
  margin-bottom: 1em;
}

.booking-info {
  font-weight: 700;
}

/* Mobile */
@media screen and (max-width: 480px) {
  .ticket-info {
    display: block;
  }
}
