.nav-bar {
  background-color: var(--off-white);
  display: flex;
  align-items: center;
  text-decoration: none;
  list-style-type: none;
  padding: .5em 4em;
  width: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 1000;
}

.temp-donate-button {
  font-size: 0.8em;
}

.logo {
  max-height: 2.5em;
}

.nav-burger-menu {
  display: none;
}

.nav-items {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 3em;
}

.nav-item {
  text-decoration: none;
  color: var(--selection-gray);
  cursor: pointer;
}

.underline {
  min-height: 1px;
  max-height: 1px;
  margin: 0.2em 1em 0em 1em;
  background-color: var(--selection-gray);
}

.nav-donate-button {
  font-family: 'Poppins';
  background-color: transparent;
  border: solid 2px var(--selection-gray);
  color: var(--selection-gray);
  padding: .5em 2em;
  text-decoration: none;
  list-style-type: none;
  cursor: pointer;
  font-weight: bold;
  margin-right: 8.5em;
  outline: none;
}

.nav-donate-button:hover {
  background-color: var(--selection-gray);
  border-color: var(--selection-gray);
  color: var(--off-white);
  outline: none;
}

.close-menu-button {
  display: none;
}

@media only screen and (max-width: 460px) {
  .logo {
    margin-left: calc(var(--mobile-text-margin) * -1);
  }
  .nav-burger-menu {
    position: relative;
    right: -40px;
  }
}

@media only screen and (max-width: 860px) {
  .nav-items {
    position: absolute;
    padding: 1em 2em 1em 4em;
    gap: 1em;
    background-color: var(--off-white);
    display: block;
    top: 0%;
    right: 0%;
    text-align: right;
    min-height: 100vh;
    transition: 250ms;
  }
  .nav-items li {
    margin: .8em 0em;
    margin-top: 30px;
    margin-right: 8.5em;
  }
  .nav-items-hide {
    right: -50%;
    transition: 250ms;
  }
  .nav-burger-menu {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: 8.5em;
  }
  .close-menu-button {
    margin-right: 8.5em;
    cursor: pointer;
    display: inline-block;
  }
  .nav-donate-button {
    display: inline-block;
    margin-right: 135px;
    margin-top: 10px;
  }
  .underline {
    display: none;
  }
}
