.imageWheelContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: -18px;
}

.imageWheelImage {
    width: 100%;
    height: var(--header-image-height);
}

.imageIndicatorContainer {
    bottom: 40px;
    display: flex;
    position: relative;
    justify-content: center;
}

.imageIndicator {
    width: 15px;
    height: 15px;
    opacity: 0.5;
    border-radius: 7.5px;
    margin-right: var(--mobile-body-margin);
    margin-left: var(--mobile-body-margin);
    background: var(--white);
    cursor: pointer;
}

.navArrowContainer {
    width: 100%;
    bottom: 250px;
    display: flex;
    align-items: center;
    position: relative;
}

.nextArrow, .backArrow {
    position: absolute;
    cursor: pointer;
}

.nextArrow {
    right: var(--web-margin);
}

.backArrow {
    left: var(--web-margin);
}

/* Mobile */
@media screen and (max-width: 480px) {
    .imageWheelContainer {
        height: 300px;
    }
    .navArrowContainer {
        bottom: 150px;
    }
    .nextArrow {
        right: 20px;
        max-height: 40px;
    }
    .backArrow {
        left: 20px;
        max-height: 40px;
    }
}
