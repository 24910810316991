.button {
  padding: .6em 3em;
  text-decoration: none;
  list-style-type: none;
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 400;
  outline: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.empty-dark {
  background-color: transparent;
  border: solid 2px var(--gray);
  color: var(--gray);
}

.empty-dark:hover {
  background-color: var(--gray);
  border-color: var(--gray);
  color: var(--off-white);
}

.full-dark {
  background-color: var(--gray);
  border-color: var(--gray);
  color: var(--off-white);
  outline: none;
}

.empty-light {
  background-color: transparent;
  border: solid 2px var(--off-white);
  color: var(--off-white);
}

.empty-light:hover {
  background-color: var(--off-white);
  border: solid 2px var(--off-white);
  color: var(--gray);
}

.full-light {
  background-color: var(--off-white);
  border: solid 2px var(--off-white);
  color: var(--gray);
}
