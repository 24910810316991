.viewStyle {
    background: var(--off-white);
    display: flex;
    padding-left: var(--web-margin);
    margin-bottom: 10px;
}

.textStyle {
    font-weight: 500;
    font-size: 12px;
    color: var(--white);
}

/* Mobile */
@media screen and (max-width: 480px) {
    .viewStyle {
        justify-content: center;
        padding: 0px var(--mobile-text-margin);
    }
    .textStyle {
        text-align: center;
    }
}