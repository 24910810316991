.pageSection {
    padding: 0px var(--web-margin) 10px;
}

.sectionTitle {
    display: flex;
    font-size: 40px;
    font-weight: 500;
    justify-content: left;
    padding: 40px 0px 30px;
}

/* Mobile */
@media screen and (max-width: 480px) {
    .pageSection {
      padding: 0px 30px 10px;
    }
    .sectionTitle {
      margin-bottom: -20px;
    }
}

/* Smaller Screen */
@media screen and (max-width: 800px) {
    .sectionTitle {
      font-size: 30px;
      text-align: left;
    }
}
