/* Global CSS Variables */

:root {
  /* Colors */
  --off-white: #F9FBFC;
  --white: #FFFFFF;
  --gray: #515A60;
  --dark-gray: #424242;
  --light-gray: #8A8A8A;
  --black: #000000;
  --info-gray: #767676;
  --selection-gray: #272C2F;
  --border-color: #E0D1D1;
  --red: #DC143C;
  /* Page Structure */
  --web-margin: 60px;
  --mobile-body-margin: 5px;
  --mobile-text-margin: 30px;
  --nav-bar-height: 56px;
  --header-image-height: 500px;
  /* Component Structure */
  --team-member-card-width: 280px;
  --team-member-card-height: 370px;
  --team-member-profile-image-size: 100px;
  --project-card-width: 270px;
  --project-card-height: 380px;
  --project-card-width-mobile: 320px;
  --project-card-height-mobile: 400px;
}

/* Text-Based Components */

body {
  margin: 0;
  font-family: 'Poppins', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  background: var(--off-white);
}

h1, h2, h3, h4, h5, p {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* Page Components */

img {
  object-fit: cover;
}

select {
  width: 100%;
  font-size: 16px;
  font-family: 'Poppins';
  border: 2px solid var(--selection-gray);
}

.load-wheel-icon {
  padding: .6em .4em;
}

@media (prefers-reduced-motion: no-preference) {
  .load-wheel-icon {
    animation: load-wheel-spin infinite 1s linear;
  }
}

@keyframes load-wheel-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}