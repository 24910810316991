.pageHeaderViewContainer {
    margin-top: var(--nav-bar-height);
}

.headerImage {
    width: 100%;
    height: 500px;
}

.projectTitle, .comingSoonInfoLabel, .infoText {
    display: flex;
    font-weight: 300;
    color: var(--gray);
    justify-content: left;
    padding: 0 var(--web-margin) 0;
}

.infoText, .textColumn {
    font-size: 16px;
    font-weight: 300;
    text-align: justify;
    color: var(--black);
}

.projectTitle {
    font-size: 70px;
    padding-top: 40px;
}

.comingSoonInfoLabel  {
    font-size: 26px;
    margin-top: 10px;
    position: relative;
    justify-content: left;
}

.infoText {
    padding: 30px var(--web-margin) 60px;
}

.textColumn {
    padding: 10px 50px 0px 30px;
    flex: 1;
}

.textRow {
    display: flex;
    padding: 30px 35px 60px;
}

.display-linebreak {
  white-space: pre-line;
}

.display-linebreak strong {
  color: var(--dark-gray);
}

/* Mobile */
@media screen and (max-width: 480px) {
    .projectTitle {
        padding-left: var(--mobile-text-margin);
        padding-right: var(--mobile-text-margin);
        /* text-align: left; */
        /* justify-content: center; */
    }
    .comingSoonInfoLabel {
        font-size: 14px;
        justify-content: center;
    }
    .infoText, .comingSoonInfoLabel {
        padding-left: var(--mobile-text-margin);
        padding-right: var(--mobile-text-margin);
    }
    .textRow {
        padding-top: 0em;
        padding-left: var(--mobile-body-margin);
        padding-right: var(--mobile-body-margin);
    }
}

/* Smaller Screen */
@media screen and (max-width: 800px) {
    .headerImage {
        height: 350px;
    }
    .projectTitle {
        font-size: 50px;
    }
}

/* Standard Screen  */
@media screen and (max-width: 1000px) {
    .textRow {
      display: inline-block;
      padding-bottom: 60px;
    }
    .textColumn {
      padding-bottom: 10px;
    }
}

/* Mobile */
@media screen and (max-width: 460px) {
    .headerImage {
        height: 250px;
    }
}

/* Small Mobile */
@media screen and (max-width: 350px) {
    .headerImage {
        height: 200px;
    }
    .projectTitle {
        font-size: 30px;
        margin-bottom: -10px;
    }
    .infoText, .textColumn {
        font-size: 12px;
    }
}
