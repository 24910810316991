.payment-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.ticket-inputs-info {
  display: flex;
  gap: 1em;
}

.quantity-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
}

@media only screen and (max-width: 900px) {
  .ticket-inputs-info {
    display: block;
  }
}
