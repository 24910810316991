.impact-section-container {
  text-align: left;
  background-color: var(--white);
  padding: 2em;
  border-radius: 5px;
  margin-bottom: 5em;
}

.impact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3em;
}

.testimonial-image {
  width: 5em;
  height: 5em;
  border-radius: 5em;
}

.testimonial-job {
  font-style: italic;
  color: var(--light-gray);
}

/* Mobile */
@media screen and (max-width: 480px) {
  .impact-section-container {
    text-align: center;
  }

  .impact-header {
    flex-direction: column;
    flex-direction: column-reverse;
    gap: 1em;
  }
}
