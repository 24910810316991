.projectsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--project-card-width), 1fr));
    column-gap: 1em;
    row-gap: 1em;
    margin: 0px 0px 40px;
}

.activeProjectsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.activeProjectsInfo {
    text-align: left;
    font-size: 16px;
    margin-bottom: 30px;
    margin-top: -20px;
    font-weight: normal;
}

.moreProjectsCard {
    width: var(--project-card-width);
    margin-left: 10px;
}

.moreProjectsCard, .moreProjectsButton, .hideProjectsButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.moreLessButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.moreProjectsButton, .hideProjectsButton {
    display: grid;
    grid-template-columns: 10fr 1fr;
    background-color: transparent;
    border: solid 2px var(--gray);
    color: var(--gray);
    padding: .5em 2em;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 200px;
    height: 60px;
    outline: none;
}

.hideProjectsButton {
    grid-template-columns: 1fr;
}

.moreProjectsButton:hover, .hideProjectsButton:hover {
    background-color: var(--selection-gray);
    border-color: var(--selection-gray);
    color: var(--off-white);
    outline: none;
}

/* Mobile
    676 used as it is the size when there is only
    one project/image per page.
*/
@media screen and (max-width: 676px) {
    .projectsContainer, .activeProjectsContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
}
