:root {
  --bullet-size: 20px;
}

.trustee-info-container {
  display: flex;
  align-items: center;
  gap: 2em;
  min-width: 25em;
  max-width: 25em;
}

.bullet-point {
  min-width: var(--bullet-size);
  max-width: var(--bullet-size);
  min-height: var(--bullet-size);
  max-height: var(--bullet-size);
  background-color: var(--dark-gray);
  border-radius: var(--bullet-size);
}

.trustee-info {
  text-align: left;
}

.trustee-job-description {
  font-size: .8em;
}

/* Mobile */
@media screen and (max-width: 480px) {
  .trustee-info-container {
    min-width: 20em;
    max-width: 20em;
  }
}
