.findUsSectionContainer {
  display: flex;
  font-size: 12px;
  margin: 4em var(--web-margin);
  color: var(--light-gray);
  justify-content: space-between;
}

.findUsTitle {
  padding: 1em 0em;
}

.findUsItem {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 1em;
  text-decoration: none;
}

.email {
  justify-content: flex-start;
}

.social {
  justify-content: flex-end;
}

.followUsTitle {
  text-align: right;
}

/* Mobile */
@media screen and (max-width: 480px) {
  .findUsSectionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin: 2em var(--mobile-text-margin);
  }

  .social {
    flex-direction: row-reverse;
  }

  .followUsTitle {
    text-align: left;
  }
}
