.titleSectionContainer {
    padding: 40px 0px;
    margin-top: 56px;
}

.title, .subtitle, .quote  {
    display: flex;
    font-weight: 300;
    font-style: normal;
    padding-left: var(--web-margin);
    padding-right: var(--web-margin);
    justify-content: center;
}

.title {
    font-size: 70px;
    color: var(--gray);
}

.subtitle {
    font-size: 16px;
    font-weight: 400;
    color: var(--light-gray);
    margin-bottom: 40px;
}

.quote {
    font-size: 30px;
    color: var(--dark-gray);
    line-height: 50px;
    margin-bottom: 20px;
}

/* Mobile */
@media screen and (max-width: 480px) {

    .title {
        font-size: 2.5em;
    }

    .titleSectionContainer {
        margin-top: 15px;
    }
    .subtitle {
        margin-bottom: 5px;
    }
    .quote {
        display: none;
    }
}
