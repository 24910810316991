.teamMemberCard {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: var(--team-member-card-width);
    max-width: var(--team-member-card-width);
    height: var(--team-member-card-height);
    margin-bottom: 6em;
    background-color: var(--white);
    padding: 1em var(--mobile-text-margin);
    text-align: left;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quoteImage {
    height: 20px;
}

.teamMemberName {
    flex: 1;
    font-weight: 400;
    font-size: 24px;
}

.teamMemberPosition {
    font-weight: 400;
    font-size: 14px;
    color: var(--info-gray);
    margin-top: 5px;
}

.teamMemberQuote {
    flex: 1;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: justify;
    font-style: italic;
    margin-top: 20px;
}

.teamMemberImage {
    width: var(--team-member-profile-image-size);
    height: var(--team-member-profile-image-size);
    border-radius: 50px;
    align-self: center;
    margin-bottom: -4em;
}
