.new-container {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  gap: 3em;
  padding-bottom: 3em;
}

.news-text-container {
  display: flex;
  flex-direction: column;
  max-width: 40em;
}

.quiz-title {
  font-weight: 600;
}

.news-image {
  max-height: 20em;
  max-width: 12em;
}

.event-info {
  display: flex;
  margin: 0.2em 0em;
}

.event-info span {
  font-weight: bold;
  margin-right: 1em;
}

.book-button {
  align-self: flex-start;
  margin-top: 3em;
}

@media only screen and (max-width: 600px) {
  .new-container {
    flex-direction: column;
  }

  .news-image {
    max-height: 20em;
  }

  .book-button {
    align-self: flex-start;
  }
}
