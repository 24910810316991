:root {
    --gallery-image-height: 180px;
}

.galleryImageContainer {
    width: var(--card-width);
    height: var(--gallery-image-height);
    margin: 10px;
    border: 1px solid var(--border-color);
}

.galleryImage {
    width: 100%;
    height: 100%;
}

/* Mobile */
@media screen and (max-width: 480px) {
    .galleryImageContainer {
        width: 100%;
    }
}
